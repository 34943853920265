import React from "react"
import LandingPage from "../components/LandingPage"
import Layout from "../components/Layout"

function landing() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <LandingPage />
      </Layout>
    </div>
  )
}

export default landing
