import * as React from "react"

function PaginationRight(props: any) {
  return (
    <svg
      width={28}
      height={30}
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M23.209 18.43l-13.97 8.265c-2.617 1.547-5.887-.387-5.887-3.483V6.682c0-3.095 3.27-5.029 5.887-3.482l13.97 8.265c2.617 1.546 2.617 5.416 0 6.965z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M7.285 2.655c-2.049 0-3.93 1.68-3.93 4.028V23.21c0 2.348 1.881 4.027 3.93 4.027.652 0 1.324-.17 1.957-.544l13.967-8.263c2.618-1.546 2.618-5.416 0-6.965L9.24 3.2a3.813 3.813 0 00-1.954-.545zm0-2.655c1.132 0 2.257.312 3.252.9l13.968 8.265c2.04 1.206 3.259 3.368 3.259 5.783 0 2.414-1.218 4.576-3.259 5.782l-13.97 8.264c-.995.59-2.12.901-3.252.901-3.595 0-6.521-2.998-6.521-6.683V6.682C.764 2.999 3.69 0 7.285 0z"
          fill="#DB8721"
        />
        <path
          opacity={0.05}
          d="M13.23 19.114c4.772 0 9.03-1.252 11.827-3.208a3.958 3.958 0 01-1.849 2.524l-13.97 8.265c-2.617 1.547-5.886-.387-5.886-3.483v-6.171c2.662 1.292 6.109 2.073 9.877 2.073z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={14.2629}
          y1={27.2385}
          x2={14.2629}
          y2={2.65549}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F19920" />
          <stop offset={0.5014} stopColor="#F7B13C" />
          <stop offset={1} stopColor="#FECC5C" />
        </linearGradient>
        <clipPath id="clip0">
          <path
            fill="#fff"
            transform="matrix(-1 0 0 1 27.764 0)"
            d="M0 0H27V29.8929H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PaginationRight
