import { ethers } from "ethers"
import {
  Erc20__factory,
  Ido__factory,
  Nft__factory,
  SellNft__factory,
} from "../types"
import { getNFTAddress, getSellNFTAddress } from "./address-helper"
import { simpleRpcProvider } from "./providers"

export function getErc20Contract(
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) {
  const signerOrProvider = signer ?? simpleRpcProvider
  return Erc20__factory.connect(address, signerOrProvider)
}

export function getIdoContract(
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider,
) {
  const signerOrProvider = signer ?? simpleRpcProvider
  return Ido__factory.connect(address, signerOrProvider)
}

export function getNftContract(
  signer?: ethers.Signer | ethers.providers.Provider,
) {
  const signerOrProvider = signer ?? simpleRpcProvider
  return Nft__factory.connect(getNFTAddress(), signerOrProvider)
}

export function getSellNftContract(
  signer?: ethers.Signer | ethers.providers.Provider,
) {
  const signerOrProvider = signer ?? simpleRpcProvider
  return SellNft__factory.connect(getSellNFTAddress(), signerOrProvider)
}
