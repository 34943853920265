import { useWeb3React, Web3ReactHooks, Web3ReactProvider } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'
import { Network } from '@web3-react/network'
import type { Connector } from '@web3-react/types'
import { useEffect } from 'react'
import { hooks as metaMaskHooks, metaMask } from './connectors/metaMask'
import { hooks as networkHooks, network } from './connectors/network'

export function getName(connector: Connector) {
  if (connector instanceof MetaMask) return 'MetaMask'
  // if (connector instanceof WalletConnect) return 'WalletConnect'
  // if (connector instanceof CoinbaseWallet) return 'Coinbase Wallet'
  if (connector instanceof Network) return 'Network'
  // if (connector instanceof GnosisSafe) return 'Gnosis Safe'
  return 'Unknown'
}

const connectors: [MetaMask | Network, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [network, networkHooks]
]

function Child() {
  const { connector, account } = useWeb3React()
  console.log(`Priority Connector is: ${getName(connector)}`)

  useEffect(() => {
    if (!account) {
      const connectedKey = localStorage.getItem('connectedKey')
      if (connectedKey === 'disconnected') {
        console.log('disconected')
      } else {
        void metaMask.connectEagerly().catch(() => {
          console.debug('Failed to connect eagerly to metamask')
        })
      }
    }
  }, [account])

  // void metaMask.connectEagerly().catch(() => {
  //   console.debug('Failed to connect eagerly to metamask')
  // })
  return null
}

export default function Web3Provider(props: { children: React.ReactNode }) {
  return (
    <Web3ReactProvider connectors={connectors}>
      <Child />
      {props.children}
    </Web3ReactProvider>
  )
}
