import * as React from "react"

function PaginationLeft(props: any) {
  return (
    <svg
      width={27}
      height={30}
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M4.555 18.43l13.97 8.265c2.617 1.547 5.886-.387 5.886-3.483V6.682c0-3.095-3.27-5.029-5.887-3.482l-13.97 8.265c-2.617 1.546-2.617 5.416 0 6.965z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M20.478 2.655c2.05 0 3.93 1.68 3.93 4.028V23.21c0 2.348-1.88 4.027-3.93 4.027-.652 0-1.323-.17-1.956-.544L4.554 18.43c-2.617-1.546-2.617-5.416 0-6.965L18.524 3.2a3.814 3.814 0 011.954-.545zm0-2.655a6.402 6.402 0 00-3.252.9L3.26 9.166C1.218 10.371 0 12.533 0 14.948c0 2.414 1.218 4.576 3.259 5.782l13.97 8.264c.995.59 2.12.901 3.252.901 3.595 0 6.521-2.998 6.521-6.683V6.682C27 2.999 24.074 0 20.478 0z"
          fill="#DB8721"
        />
        <path
          opacity={0.05}
          d="M14.534 19.114c-4.772 0-9.03-1.252-11.827-3.208a3.958 3.958 0 001.849 2.524l13.97 8.265c2.616 1.547 5.886-.387 5.886-3.483v-6.171c-2.663 1.292-6.11 2.073-9.877 2.073z"
          fill="#1A1A1A"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1={13.5007}
          y1={27.2385}
          x2={13.5007}
          y2={2.65549}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F19920" />
          <stop offset={0.5014} stopColor="#F7B13C" />
          <stop offset={1} stopColor="#FECC5C" />
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H27V29.8929H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PaginationLeft
