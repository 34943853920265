import addresses from "../constants/contracts"
import { Address } from "../constants/types"

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  //@ts-ignore
  return address[chainId] ? address[chainId] : address[56]
}

export const getSellNFTAddress = () => {
  return getAddress(addresses.sellNft)
}
export const getNFTAddress = () => {
  return getAddress(addresses.nft)
}
