import { useMemo } from "react"
import {
  getErc20Contract,
  getIdoContract,
  getNftContract,
  getSellNftContract,
} from "../lib/contract-accessor"
import useActiveWeb3React from "./useWeb3"

export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(
    () => getErc20Contract(address, library.getSigner()),
    [address, library],
  )
}

export const useIdoContract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(
    () => getIdoContract(address, library.getSigner()),
    [address, library],
  )
}

export const useNftContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftContract(library.getSigner()), [library])
}

export const useSellNftContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSellNftContract(library.getSigner()), [library])
}
