import React from "react"
import Footer from "./Footer"
// import Header from "./Header"
import MainNav from "./MainNav"

function Layout(props: { children: React.ReactNode }) {
  return (
    <div className="min-h-screen">
      <MainNav />
      {props.children}
      <Footer />
    </div>
  )
}

export default Layout
